import React from 'react';
import PropTypes from 'prop-types';
import {
  FormatDateTime,
  DATE_FORMAT_LONG,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  DATE_FORMAT_LONG_WITH_TIME,
  DATE_FORMAT_MERIDIEM,
} from 'sharedWebpack/helpers/dateTime';

const DateTime = ({ dateTime, format, displayInUTC, displayWithoutConversion }) => (
  <span>
    {dateTime && FormatDateTime({ dateTime, format, displayInUTC, displayWithoutConversion })}
  </span>
);

DateTime.defaultProps = {
  dateTime: null,
  format: DATE_FORMAT_SHORT,
  displayInUTC: true,
  displayWithoutConversion: false,
};

DateTime.propTypes = {
  dateTime: PropTypes.string,
  format: PropTypes.oneOf([
    DATE_FORMAT_LONG,
    DATE_FORMAT_SHORT,
    DATE_FORMAT_SHORT_WITH_TIME,
    DATE_FORMAT_LONG_WITH_TIME,
    DATE_FORMAT_MERIDIEM,
  ]),
  displayInUTC: PropTypes.bool,
  displayWithoutConversion: PropTypes.bool,
};

export default DateTime;
