/* global I18n */

import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { mapTravelerData } from '../../lib/helpers/mapCustomerData';
import { reportToRollbar, fetchFOPandCardDetails, cartFetched } from './';
import history from '../../lib/history';

export const SEARCHING_CUSTOMERS = 'SEARCHING_CUSTOMERS';
function searchingCustomers(index, query, callbackParams) {
  return {
    type: SEARCHING_CUSTOMERS,
    index,
    query,
    callback_params: callbackParams,
  };
}

export const CUSTOMER_SEARCH_COMPLETE = 'CUSTOMER_SEARCH_COMPLETE';
function customerSearchComplete(index, customers, callbackParams) {
  return {
    type: CUSTOMER_SEARCH_COMPLETE,
    index,
    customers,
    callback_params: callbackParams,
  };
}

export const CUSTOMER_SEARCH_CANCELLED = 'CUSTOMER_SEARCH_CANCELLED';
function customerSearchCancelled(index) {
  return {
    type: CUSTOMER_SEARCH_CANCELLED,
    index,
  };
}
export function searchCustomerWithoutHandling(query, callbackParams) {
  return httpClient.get(routes.api.customers.search({ q: query, callbackParams }));
}

export function searchCustomer(index, query, callbackParams) {
  return dispatch => {
    dispatch(searchingCustomers(index, query, callbackParams));

    if (query && query.length) {
      return searchCustomerWithoutHandling(query, callbackParams).then(({ data }) =>
        dispatch(customerSearchComplete(index, data, callbackParams)),
      );
    }
    return dispatch(customerSearchCancelled(index));
  };
}

export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export function customerSelected(index, customer) {
  return {
    type: CUSTOMER_SELECTED,
    index,
    customer,
  };
}

export const SET_RESO_EMAIL_OPTION = 'SET_RESO_EMAIL_OPTION';
export function setResoEmailOption(value) {
  return {
    type: SET_RESO_EMAIL_OPTION,
    value,
  };
}

export const SET_RESO_PHONE_OPTION = 'SET_RESO_PHONE_OPTION';
export function setResoPhoneOption(value) {
  return {
    type: SET_RESO_PHONE_OPTION,
    value,
  };
}

export function setResoOptionEmail(value) {
  return dispatch => {
    dispatch(setResoEmailOption(value));
  };
}

export function setResoOptionPhone(value) {
  return dispatch => {
    dispatch(setResoPhoneOption(value));
  };
}

export const SAVE_EMAIL_PHONE_TO_CART = 'SAVE_EMAIL_PHONE_TO_CART';
export function saveEmailPhoneToCart(cartId, stateData) {
  return (dispatch, getState) => {
    const cart = getState().common.cart;
    const passEmailToAirline = cart.resoOptions.passEmailToAirline;
    const passPhoneToAirline = cart.resoOptions.passPhoneToAirline;
    dispatch({ type: SAVE_EMAIL_PHONE_TO_CART, data: stateData });
    return httpClient
      .patch(routes.api.carts.updateCart({ cartId }), {
        pass_email_to_airline: passEmailToAirline,
        pass_phone_to_airline: passPhoneToAirline,
      })
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}

export const SAVING_TRAVELER_DETAILS = 'SAVING_TRAVELER_DETAILS';
export function savingTravelerDetails(index, data) {
  return { type: SAVING_TRAVELER_DETAILS, index, data };
}

export const TRAVELERS_SAVING_FAILED = 'TRAVELERS_SAVING_FAILED';
export function travelersSavingFailed(index, data) {
  const errorMessage = data.error;
  const error = { travelerIndex: index, errorMessage };
  return { type: TRAVELERS_SAVING_FAILED, index, error };
}

export const TRAVELER_DETAILS_SAVED = 'TRAVELER_DETAILS_SAVED';
export function travelerDetailsSaved(index, id) {
  return { type: TRAVELER_DETAILS_SAVED, index, id };
}

export const SAVING_TRAVELER_DETAILS_FAILED = 'SAVING_TRAVELER_DETAILS_FAILED';
export function savingTravelerDetailsFailed(index) {
  return { type: SAVING_TRAVELER_DETAILS_FAILED, index };
}

export function saveTravelerDetailsInBackend(
  index,
  data,
  cartId,
  callbackParams,
  readOnlyTravelArrangerWithUnchangedData,
) {
  const customerData = mapTravelerData(
    Object.entries(data).reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {}),
  );
  if (data.id) {
    customerData.id = data.id;
  } else {
    customerData.id = null;
  }
  if (data.contactInformationCopied) {
    customerData.contact_information_copied = data.contactInformationCopied;
  }
  if (data.associateOrganization) customerData.associate_organization = data.associateOrganization;
  if (index === 0 && readOnlyTravelArrangerWithUnchangedData) {
    return httpClient.put(
      routes.api.carts.people.selectCartPeopleAndAddDefaultCostCentter({ cartId, sequence: index }),
      { person_id: customerData.id, callback_params: callbackParams },
    );
  }

  return httpClient.put(
    routes.api.carts.people.setPeopleAndAddDefaultCostCenter({ cartId, sequence: index }),
    {
      traveler: customerData,
      callback_params: callbackParams,
    },
  );
}

export const UPDATE_TRAVELERS_SAVING_STATUS = 'UPDATE_TRAVELERS_SAVING_STATUS';
export function updateTravelerSavingStatus(status) {
  return {
    type: UPDATE_TRAVELERS_SAVING_STATUS,
    status,
  };
}

export function saveTravelerDetails(
  index,
  data,
  callbackParams,
  readOnlyTravelArrangerWithUnchangedData,
) {
  return (dispatch, getState) => {
    if (data.frequentFlyerNumber) {
      if (!data.frequentFlyerNumber.carrier && !data.frequentFlyerNumber.number) {
        // We are deleting data.frequentFlyerNumber, because it has empty string as its value, and we don't want to send it to the backend.
        // eslint-disable-next-line no-param-reassign
        delete data.frequentFlyerNumber;
      }
    }
    dispatch(savingTravelerDetails(index, data));
    const cartId = getState().common.cart.id;
    dispatch(saveEmailPhoneToCart(cartId, data));
    return saveTravelerDetailsInBackend(
      index,
      data,
      cartId,
      callbackParams,
      readOnlyTravelArrangerWithUnchangedData,
    )
      .then(response => {
        dispatch(travelerDetailsSaved(index, response.data.id));
      })
      .catch(error => {
        if (error.response.status === 422) {
          dispatch(travelersSavingFailed(index, error.response.data));
        } else {
          const errorMessage = {
            error: "Traveller details couldn't be saved",
          };
          dispatch(reportToRollbar(errorMessage.error, error));
          dispatch(travelersSavingFailed(index, errorMessage));
        }
      });
  };
}

export const RESET_TRAVELER_DETAILS = 'RESET_TRAVELER_DETAILS';
export function resetTravelerDetails(index) {
  return {
    type: RESET_TRAVELER_DETAILS,
    index,
  };
}

export const RESET_TRAVELERS_ERROR = 'RESET_TRAVELERS_ERROR';
export function resetTravelersError() {
  return {
    type: RESET_TRAVELERS_ERROR,
  };
}

export const FETCHING_CURRENT_CUSTOMER = 'FETCHING_CURRENT_CUSTOMER';
function fetchingCurrentCustomer() {
  return { type: FETCHING_CURRENT_CUSTOMER };
}

export const CURRENT_CUSTOMER_FETCHED = 'CURRENT_CUSTOMER_FETCHED';
function currentCustomerFetched(customer) {
  return { type: CURRENT_CUSTOMER_FETCHED, customer };
}

export function fetchCurrentCustomer(callbackParams) {
  return dispatch => {
    dispatch(fetchingCurrentCustomer());

    return httpClient
      .get(routes.api.customers.currentCustomer({ callbackParams }))
      .then(response => {
        dispatch(currentCustomerFetched(response.data));
        return response.data;
      });
  };
}

export const CUSTOMER_DATA_READ = 'CUSTOMER_DATA_READ';
export function saveCustomerToState(data) {
  return {
    type: CUSTOMER_DATA_READ,
    data,
  };
}
export function getStoredCustomerData(id, callbackParams) {
  return dispatch =>
    httpClient
      .get(routes.api.carts.people.fetch({ cartId: id, callbackParams }))
      .then(({ data }) => {
        const travelers = data.map(traveler => {
          const travelerData = traveler;
          travelerData.data.document.type = traveler.data.document.documentType;
          delete travelerData.data.document.documentType;
          return travelerData.data;
        });
        return dispatch(saveCustomerToState(travelers));
      });
}

export const PROCEED_TO_PAYMENT_PAGE_VIEW = 'PROCEED_TO_PAYMENT_PAGE_VIEW';
export function updateProceedToPaymentPageView(proceedToPaymentPageView) {
  return {
    type: PROCEED_TO_PAYMENT_PAGE_VIEW,
    proceedToPaymentPageView,
  };
}

export function proceedToPayment() {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    const travelerHasError = getState().common.cart.travelersError.errorList.length > 0;

    if (!travelerHasError) {
      dispatch(resetTravelersError());
      dispatch(fetchFOPandCardDetails());
      history.push(`/orders/${cartId}/payment`);
    }

    dispatch(updateTravelerSavingStatus(false));
    dispatch(updateProceedToPaymentPageView(false));
  };
}

export function fetchCartPeople(callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    dispatch(getStoredCustomerData(cartId, callbackParams));
  };
}
