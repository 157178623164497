import React from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { FormatDateTime, DATE_FORMAT_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import AirportChangeSegment from 'sharedWebpack/AirportChangeSegment';
import './styles.scss';

const StopoverDetails = ({ findAirport, stopover, airportChangeMap }) => {
  const { airportCode, arrivalLocaltime, arrivalLocaltimeInUserTz } = stopover;
  const stopoverArrivalLocaltime = arrivalLocaltime || arrivalLocaltimeInUserTz;

  const viewAirportDetails = airport => {
    const { name, placeName, countryName } = airport;
    const values = [name, placeName, countryName].filter(value => !!value);
    return values.join(', ');
  };

  const airport = findAirport(airportCode);
  const updatedAirportCode = airport && airport.code;
  const hasAirportChange = airportChangeMap.has(updatedAirportCode);
  const airportChangeData = hasAirportChange && airportChangeMap.get(updatedAirportCode);

  return (
    <Card className="duration-stopover__card" key={stopover.airportCode} version="v2">
      <div className="col-grid col-bleed direction-row align-center justify-space-between stopover-details">
        <div className="stopover-details__country">
          {airport && (
            <img
              className="stopover-details__flag"
              src={airport.countryFlag}
              alt={airport.countryName}
            />
          )}
          {stopover.airportCode}
        </div>
      </div>
      {airport && airport.name && (
        <div className="stopover-details__airport-name">{viewAirportDetails(airport)}</div>
      )}
      <div className="stopover-details__arrival-time">
        <DateTimeLocaleInterpolationWrapper
          localeKey="components.flight.stopover.arrival"
          time={FormatDateTime({
            dateTime: stopoverArrivalLocaltime,
            format: DATE_FORMAT_SHORT_WITH_TIME,
          })}
        />
      </div>
      {hasAirportChange && (
        <AirportChangeSegment
          destinationAirportDetails={findAirport(airportChangeData.arrival)}
          changedAirportDetails={findAirport(airportChangeData.departure)}
        />
      )}
    </Card>
  );
};

StopoverDetails.propTypes = {
  findAirport: PropTypes.func.isRequired,
  stopover: PropTypes.shape({
    airportCode: PropTypes.string,
    arrivalLocaltime: PropTypes.string,
    arrivalLocaltimeInUserTz: PropTypes.string,
  }).isRequired,
  airportChangeMap: PropTypes.func.isRequired,
};

export default StopoverDetails;
